

const akkordeon = document.querySelectorAll('.item');

const akkordeonProdukte = document.querySelectorAll('.info');

akkordeon.forEach(function(akkordeon){
    akkordeon.addEventListener('click', function(){
        let info = akkordeon.querySelector('.item-content');
        if (akkordeon.classList.contains('active')) {
            akkordeon.classList.remove('active');
            info.classList.remove('active');
        } else {
            akkordeon.classList.add('active');
            info.classList.add('active');
        }
    })
})


akkordeonProdukte.forEach(function(akkordeon){
    akkordeon.addEventListener('click', function(){
        let info = akkordeon.querySelector('.info-content');
        if (akkordeon.classList.contains('active')) {
            akkordeon.classList.remove('active');
            info.classList.remove('active');
        } else {
            akkordeon.classList.add('active');
            info.classList.add('active');
        }
    })
})

