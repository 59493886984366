require("@netprofit/blockmapsfonts");

require("./mobileMenu.js");
require("./akkordeon.js");
require("./scroll.js");

require("./swiper.js");

//require('./scrollText.js');

require('./scrollElement.js');

require('./search.js');

require('./scrollMagic.js');

require("./youtube.js");

const AOS = require("aos");
    AOS.init({
    duration: 600,
    easing: 'ease-out-sine',
    startEvent: 'load'
    });

    document.querySelectorAll("img").forEach(img => {
    img.addEventListener("load", () => {
    AOS.refresh();
    })
})
