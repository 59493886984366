/*--------------------------------------------------------
Google-Maps-Karte Fonts nicht laden
Preventing the Google Maps libary from downloading an extra font
https://stackoverflow.com/questions/25523806/google-maps-v3-prevent-api-from-loading-roboto-font
--------------------------------------------------------*/
var head = document.querySelector("head");
var insertBefore = head.insertBefore;
head.insertBefore = function (newElement, referenceElement) {
    // intercept font download
    if (newElement.href &&
        (newElement.href.indexOf("//fonts.googleapis.com/css?family=Roboto") !== -1 || 
        newElement.href.indexOf("//fonts.googleapis.com/css?family=Google+Sans+Text") !== -1 || 
        newElement.href.indexOf("//fonts.gstatic.com/s/roboto/") !== -1)) {
        return;
    }
    // intercept style elements for IEs
    if (
        newElement.tagName.toLowerCase() === "style" &&
        newElement.styleSheet &&
        newElement.styleSheet.cssText &&
        newElement.styleSheet.cssText.replace("\r\n", "").indexOf(".gm-style") === 0
    ) {
        return;
    }
    // intercept style elements for other browsers
    if (
        newElement.tagName.toLowerCase() === "style" &&
        newElement.innerHTML &&
        newElement.innerHTML.replace("\r\n", "").indexOf(".gm-style") === 0
    ) {
        return;
    }
    insertBefore.call(head, newElement, referenceElement);
};
