const popupIcon = document.querySelectorAll('.content-icon');
const popupElement = document.querySelector('.popup-text');
const closeIcon = document.querySelector('.close-popup');
const body = document.querySelector('body');

if (popupIcon.length > 0) {
    popupIcon.forEach(function(item) {
        item.addEventListener('click', function() {
            if (popupElement.classList.contains('active')) {
                popupElement.classList.remove('active');
            } else {
                popupElement.classList.add('active');
                let text = item.getAttribute('data-text');
                document.querySelector('.popup-container .text').innerHTML = text;
            }
        });
    });

    closeIcon.addEventListener('click', function() {
        popupElement.classList.remove('active');
        document.querySelector('.popup-container .text').innerHTML = '';
    });
}



