let menuButton = document.querySelector('.menu');
let menu = document.querySelector('.mobile.container');

menuButton.addEventListener('click', function(){
    if (menuButton.classList.contains('active')){
        menuButton.classList.remove('active');
        menu.classList.remove('active');
        preventScroll(0);
    } else {
        menuButton.classList.add('active');
        menu.classList.add('active');
        preventScroll(1);
    }
})

const mobileSubmenu = document.querySelectorAll('li.submenu');
mobileSubmenu.forEach(function(submenu) {
    submenu.addEventListener('click', function(event) {
        event.stopPropagation();

        if (submenu.classList.contains('active')) {
            submenu.classList.remove('active');
        } else {
            submenu.classList.add('active');
        }
    });
});


function preventScroll(status){
    if(status > 0){
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'unset';
    }
}