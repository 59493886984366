import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';


Swiper.use([Navigation]);

let contentSwiper = null;



contentSwiper = new Swiper(".ContentSwiper", {
    watchSlidesProgress: true,
    slidesPerView: 'auto',
    spaceBetween: 30,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      769: {
        slidesPerView: 3,
      },
    }
   
  });
