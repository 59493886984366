// Produkt Icons Scroll

const icons = document.querySelectorAll('.icon-item img');
let scrollTimeout;

document.addEventListener('scroll', function() {
    icons.forEach(function(icon) {
        if(isElementInViewport(icon)){
            icon.classList.add('scrolled');
        }
    });

    clearTimeout(scrollTimeout);

    scrollTimeout = setTimeout(function() {
        icons.forEach(function(icon) {
            icon.classList.remove('scrolled');
        });
    }, 1000);
});

// Produkt Icons Scroll ende 

function isElementInViewport(element) {
    var rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}










