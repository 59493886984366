/*--------------------------------------------------------
    Youtube
--------------------------------------------------------*/
document.addEventListener('DOMContentLoaded', function () {
    // Click event for YouTube Opt-In
    document.querySelectorAll('.videoWrapper .overlay .play').forEach(function (playButton) {
        playButton.addEventListener('click', function () {
            document.querySelectorAll('.videoWrapper .overlay').forEach(function (overlay) {
                overlay.style.display = 'none';
            });
            document.querySelectorAll('.videoWrapper').forEach(function (videoWrapper) {
                videoWrapper.classList.add('loading');
                var iframe = videoWrapper.querySelector('iframe');
                iframe.setAttribute('src', iframe.getAttribute('video-url'));
            });
            localStorage.setItem('YouTube', 'show');
        });
    });

    // Check localStorage on document ready
    if (localStorage.getItem('YouTube') === 'show') {
        document.querySelectorAll('.videoWrapper .overlay').forEach(function (overlay) {
            overlay.style.display = 'none';
        });
        document.querySelectorAll('.videoWrapper').forEach(function (videoWrapper) {
            videoWrapper.classList.add('loading');
            var iframe = videoWrapper.querySelector('iframe');
            iframe.setAttribute('src', iframe.getAttribute('video-url'));
        });
    }
});