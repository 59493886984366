
import ScrollMagic from 'scrollmagic';

let multiply = 7;

const scrollText = document.querySelectorAll('.ce_rsce_scrollText');

scrollText.forEach(elem => {
    var controller = new ScrollMagic.Controller();
    let hook = 0.7;
    if(window.innerHeight < 1080) hook = 0.55;
    var scene = new ScrollMagic.Scene({
        triggerElement: elem.querySelector(".trigger"), 
        triggerHook: hook, duration: "150%"})
		
        //.setPin(elem.querySelector(".pin"))
		.addTo(controller);

    if(window.innerHeight < 1080){ multiply = 1;}

    scene.on("progress", (event) => {
        let progress = (Math.round((event.progress - 1) * 10000) / 10000).toFixed(4);
        elem.style.setProperty("--progress", ((parseFloat(progress) + 1).toFixed(4) * multiply).toFixed(4));
        if(progress != 0 && progress != -1){
            elem.classList.add("active");
        }else{
            elem.classList.remove("active");
        }
    });

    function refreshScene(scene){
        scene.update();
        requestAnimationFrame(() => {
            refreshScene(scene);
        });
    }
    refreshScene(scene);


})



const scrollElement = document.querySelectorAll('.ce_rsce_scrollElement');



scrollElement.forEach(elem => {
    var controller = new ScrollMagic.Controller();
    let hook = 0.2;
    if(window.innerHeight < 1080) hook = 0.15;
    var scene = new ScrollMagic.Scene({
        triggerElement: elem.querySelector(".trigger"), 
        triggerHook: hook, duration: "150%"})
		
        .setPin(elem.querySelector(".pin"))
		.addTo(controller);

    if(window.innerHeight < 1080){ multiply = 1;}

    scene.on("progress", (event) => {
        let progress = (Math.round((event.progress - 1) * 10000) / 10000).toFixed(4);
        elem.style.setProperty("--progress", ((parseFloat(progress) + 1).toFixed(4) * multiply).toFixed(4));
        if(progress != 0 && progress != -1){
            elem.classList.add("active");
        }else{
            elem.classList.remove("active");
        }
    });

    function refreshScene(scene){
        scene.update();
        requestAnimationFrame(() => {
            refreshScene(scene);
        });
    }
    refreshScene(scene);


})
